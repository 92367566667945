import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `reset-password`,
        component: React.lazy(() =>import('views/auth-views/authentication/reset-password')),
    }
 
]

export const protectedRoutes = [
    {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component: React.lazy(() => import('views/app-views/profile')),
    },
    {
        key: 'ai-settings',
        path: `${APP_PREFIX_PATH}/ai-settings`,
        component: React.lazy(() => import('views/app-views/ai-settings')),
    },
    {
        key: 'password',
        path: `${APP_PREFIX_PATH}/change-password`,
        component: React.lazy(() => import('views/app-views/password')),
    },
    {
        key: 'privacy',
        path: `${APP_PREFIX_PATH}/privacy`,
        component: React.lazy(() => import('views/app-views/privacy')),
    },
    {
        key: 'superpageEditor',
        path: `${APP_PREFIX_PATH}/editor`,
        component: React.lazy(() => import('views/app-views/editor')),
    },
    {
        key: 'workspace',
        path: `${APP_PREFIX_PATH}/workspace`,
        component: React.lazy(() => import('views/app-views/workspace')),
    },
    {  
        key: 'pages',
        path: `${APP_PREFIX_PATH}/pages`,
        component: React.lazy(() => import('views/app-views/pages'))

    },
    {
        key: 'integrations',
        path: `${APP_PREFIX_PATH}/integrations`,
        component: React.lazy(() => import('views/app-views/integrations')),
    },

    {
        key: 'integrations.list',
        path: `${APP_PREFIX_PATH}/integrations/list`,
        component: React.lazy(() => import('views/app-views/integrations/IntegrationsList')),
    },
    {
        key:'bonus',
        path:`${APP_PREFIX_PATH}/bonus`,
        component: React.lazy(() => import('views/app-views/bonus')),
    },
    {
        key:'tutorials',
        path:`${APP_PREFIX_PATH}/tutorials`,
        component: React.lazy(() => import('views/app-views/tutorials')),
    },
    {
        key:'white-label-tutorials',
        path:`${APP_PREFIX_PATH}/tutorial`,
        component: React.lazy(() => import('views/app-views/wl-tutorial')),

    },
    {
        key:'agency',
        path:`${APP_PREFIX_PATH}/agency`,
        component: React.lazy(() => import('views/app-views/agency')),
    },
    {
        key:'teams',
        path:`${APP_PREFIX_PATH}/teams`,
        component: React.lazy(() => import('views/app-views/teams')),
    },
    {
        key:'chatbot',
        path:`${APP_PREFIX_PATH}/chatbot`,
        component: React.lazy(() => import('views/app-views/chatbot')),
    },
    {
        key:'building',
        path:`${APP_PREFIX_PATH}/building`,
        component: React.lazy(() => import('views/app-views/building')),
    },
    {
        key:'leads',
        path:`${APP_PREFIX_PATH}/leads`,
        component: React.lazy(() => import('views/app-views/leads')),
    },
    {
        key:'builder',
        path:`${APP_PREFIX_PATH}/builder`,
        component: React.lazy(() => import('views/app-views/content-box')),

    },
    {
        key:'mediaList',
        path:`${APP_PREFIX_PATH}/mediaList`,
        component: React.lazy(() => import('views/app-views/media/mediaList')),
    },
    {
        key:'master',
        path:`${APP_PREFIX_PATH}/master`,
        component: React.lazy(() => import('views/app-views/master')),
    },
    {
        key:'master-transactions',
        path:`${APP_PREFIX_PATH}/master/transactions`,
        component: React.lazy(() => import('views/app-views/master/Transactions')),
},
    {
        key:'jvzoo-transactions',
        path:`${APP_PREFIX_PATH}/master/jvzoo`,
        component: React.lazy(() => import('views/app-views/master/JvzooTransaction')),
    },
    {
        key:'plans',
        path:`${APP_PREFIX_PATH}/master/plans`,
        component: React.lazy(() => import('views/app-views/master/Plans')),
    },
    {
        key:'bonuses',
        path:`${APP_PREFIX_PATH}/master/bonus`,
        component: React.lazy(() => import('views/app-views/master/Bonuses')),
    },
    {
        key:'upgrade',
        path:`${APP_PREFIX_PATH}/upgrade`,
        component: React.lazy(() => import('views/app-views/upgrade')),
    },
    {
        key: 'impersonate',
        path: `impersonate`,
        component: React.lazy(() =>import('views/auth-views/authentication/impersonate')),
    },
    {
        key: 'whitelabelUser',
        path: `whitelabel/users`,
        component: React.lazy(() =>import('views/app-views/whitelabel')),

    },
    {
        key: 'whitelabelUser',
        path: `whitelabel/tutorial`,
        component: React.lazy(() =>import('views/app-views/whitelabel/Tutorial')),

    },
    {
        key: 'whitelabelSettings',
        path: `whitelabel/settings`,
        component : React.lazy(() =>import('views/app-views/whitelabel/Settings'))
    },
    {
        key: 'whitelabelSMTP',
        path: `whitelabel/smtp`,
        component : React.lazy(() =>import('views/app-views/whitelabel/SmtpSettingsForm'))
    },
    {
        key: 'savedSections',
        path: `saved-sections`,
        component: React.lazy(() =>import('views/app-views/saved-sections')),
    },
    {
        key: 'saveSection',
        path: `save-sections`,
        component: React.lazy(() =>import('views/app-views/saved-sections/SaveSection')),
    },


    
]